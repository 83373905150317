import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'pt',
    resources: {
      en: {
        translation: {
            subtitle: 'My proposal is to unite law and technology to provide intelligent solutions to legal issues.',
            tagline: 'Welcome!',
            cv: 'View curriculum',
            nbmainpage: 'Main Page',
            skills: 'Skills',
            experiences: 'Experiences',
            contact: 'Contact',
            lawyer: 'Lawyer',
            developer: 'Developer',
            international: 'International',
            researcher: 'Researcher',
            details_laywer: 'A graduate of the Federal University of Paraná, I have experience in law firms, lawtechs, and the judiciary.',
            details_developer: 'With 2 years of experience working with software development, I have created several applications for solving legal and non-legal issues.',
            details_international: 'In addition to working for an international company, I participated in international programs related to Internet and law, such as the',
            details_researcher: 'Besides writing some scientific articles published in book chapters, events, and even poetry published, I have experience in writing legal pieces, ',
            details_researcher_end: '.',
            book_chapters: 'book chapters',
            events: 'events',
            and: 'and',
            professional: 'Professional',
            academic: 'Academic',
            developed_by: 'Developed by Arthur A. de Menezes',
            law: 'Law',
            ads: 'Systems Analysis and Development',
            up: 'Positivo University',
            ufpr: 'Federal University of Paraná',
            developer: 'Developer',
            pp: 'Attorneys at Law',
            intern: 'Intern -',
            legalintern: 'Legal Intern',
            tjpr: 'Court of Justice of the State of Paraná',
          },
      },
      pt: {
        translation: {
            subtitle: 'Minha proposta é unir o direito à tecnologia para fornecer soluções inteligentes para questões jurídicas.',
            tagline: 'Bem-vindo!',
            cv: 'Acessar currículo',
            nbmainpage: 'Página Principal',
            skills: 'Habilidades',
            experiences: 'Experiências',
            contact: 'Contato',
            lawyer: 'Advogado',
            developer: 'Desenvolvedor',
            international: 'Internacional',
            researcher: 'Pesquisador',
            details_laywer: 'Formado pela Universidade Federal do Paraná, tenho experiência em escritórios de advocacia, em lawtechs e no poder judiciário. OAB/PR nº 118.662.',
            details_developer: 'Com 2 anos de experiência trabalhando com desenvolvimento de software, criei diversas aplicações para resolução de questões jurídicas e não-jurídicas.',
            details_international: 'Além de trabalhar para uma empresa internacional, participei de programas internacionais relacionados com Internet e Direito, como o',
            details_researcher: 'Além de escrever alguns artigos científicos publicados em ',
            details_researcher_end: ' e até poesias publicadas, tenho prática na escrita de peças jurídicas.',
            book_chapters: 'capítulo de livros',
            events: 'eventos',
            and: 'e',
            professional: 'Profissional',
            academic: 'Acadêmica',
            developed_by: 'Desenvolvido por Arthur A. de Menezes',
            law: 'Direito',
            ads: 'Análise e Desenvolvimento de Sistemas',
            up: 'Universidade Positivo',
            ufpr: 'Universidade Federal do Paraná',
            developer: 'Desenvolvedor',
            pp: 'Sociedade de Advogados',
            intern: 'Estagiário -',
            legalintern: 'Estagiário Jurídico',
            tjpr: 'Tribunal de Justiça do Estado do Paraná',
          }
      }
    }
  });

export default i18n;