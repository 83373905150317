import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import lnIcon from '../assets/img/nav-icon1.svg';
import gitHubIcon from '../assets/img/github-icon.svg';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";
import ReactCountryFlag from "react-country-flag"
import { useTranslation, Trans } from 'react-i18next';

export const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [isActive, setIsActive] = useState(i18n.language !== 'en');

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const onLanguageChange = (langCode) => {
    i18n.changeLanguage(langCode);
    setIsActive(current => !current);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          
        <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-name' : 'navbar-name'} onClick={() => onUpdateActiveLink('home')}>Arthur A. de Menezes</Nav.Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}><Trans i18nKey="nbmainpage"></Trans></Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}><Trans i18nKey="skills"></Trans></Nav.Link>
              <Nav.Link href="#qualification" className={activeLink === 'qualification' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('qualification')}><Trans i18nKey="experiences"></Trans></Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/arthurademenezes/" target="_blank" rel="noopener noreferrer"><img src={lnIcon} alt="Linkedin Icon" /></a>
                <a href="https://github.com/arthurademenezes/" target="_blank" rel="noopener noreferrer"><img src={gitHubIcon} alt="Github Icon" /></a>
              </div>               
              <HashLink to='#connect'>
                <button className="vvd"><span><Trans i18nKey="contact"></Trans></span></button>
              </HashLink>
              <div className="change-language">
              <button style={{ border: isActive ? '1px solid white' : ''}}
                 className="pt" href="#" onClick={ () => onLanguageChange('pt') } id="flag__br"> 
                 <ReactCountryFlag countryCode="BR" svg /></button>
              <button style={{ border: isActive ? '' : '1px solid white'}} 
                className="en" href="#" onClick={ () => onLanguageChange('en') } id="flag__en">
                <ReactCountryFlag countryCode="GB" svg /></button>
              </div>
            </span>
          </Navbar.Collapse>
          <div className="change-language-mb">
        <button style={{ border: isActive ? '1px solid white' : ''}}
          className="pt" href="#" onClick={ () => onLanguageChange('pt') } id="flag__br"> 
          <ReactCountryFlag countryCode="BR" svg /></button>
        <button style={{ border: isActive ? '' : '1px solid white'}} 
          className="en" href="#" onClick={ () => onLanguageChange('en') } id="flag__en">
          <ReactCountryFlag countryCode="GB" svg /></button>
      </div>
        </Container>
      </Navbar>
    </Router>
  )
}