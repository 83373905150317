import 'animate.css';
import { RiBriefcase3Line } from 'react-icons/ri';
import { FaUniversity } from 'react-icons/fa';
import { useState } from "react";
import { Trans } from 'react-i18next';

export const Qualification = () => {

  const [activeTab, setActiveTab] = useState('work');

  const showWorkTab = () => setActiveTab('work');

  const showEducationTab = () => setActiveTab('education');

  return (
    <section className="qualification" id="qualification">
    <h2 className="section__title"><Trans i18nKey="experiences"></Trans></h2>
    {/* <span className="section__subtitle">Minhas experiências</span> */}

    <div className="qualification__container container">
      <div className="qualification__tabs">
      <div  className={`qualification__button button--flex 
      ${activeTab === 'work' ? 'qualification__active' : ''}`} onClick={showWorkTab}>
            <button className="uil uil-briefcase-alt qualification__icon">
              <RiBriefcase3Line /></button>
            <Trans i18nKey="professional"></Trans>
      </div>
          
          <div className={`qualification__button button--flex 
          ${activeTab === 'education' ? 'qualification__active' : ''}`} onClick={showEducationTab}>
            <button className="uil uil-graduation-cap qualification__icon">
              <FaUniversity /></button>
            <Trans i18nKey="academic"></Trans>
          </div>
        </div>

      <div className="qualification__sections">
      <div className={`qualification__content ${activeTab === 'work' ? '' : 'qualification__hide'}`}>
          <div className="qualification__data">
            <div className="qualification_textbox">
            <h3 className="qualification__title">Software Developer</h3>
              <span className="qualification__subtitle">Scissero</span>
              <div className="qualification__calender">
                <i className="uil uil-calendar-alt"></i>2022-atual
              </div>
            </div>

            <div className='line'>
              <span className="qualification__rounder"></span>
              <span className="qualification__line"></span>
            </div>
          </div>
          <div className="qualification__data">
            <div></div>
            <div>
              <span className="qualification__rounder"></span>
              <span className="qualification__line"></span>
            </div>
            <div className="qualification_textbox">
            <h3 className="qualification__title"><Trans i18nKey="developer"></Trans></h3>
              <span className="qualification__subtitle">Poletto & Possamai <Trans i18nKey="pp"></Trans></span>
              <div className="qualification__calender">
                <i className="uil uil-calendar-alt"></i> 2021-2022
              </div>
            </div>
          </div>

          <div className="qualification__data">
            <div className="qualification_textbox margin-qualification-thing">
            <h3 className="qualification__title"><Trans i18nKey="intern"></Trans> BI</h3>
              <span className="qualification__subtitle">GO4! Solutions/
Lawvision</span>
              <div className="qualification__calender">
                <i className="uil uil-calendar-alt"></i> 2021-2021
              </div>
            </div>
            <div className='line'>
              <span className="qualification__rounder"></span>
              <span className="qualification__line"></span>
            </div>
          </div>

          <div className="qualification__data">
            <div></div>
            <div>
              <span className="qualification__rounder"></span>
              <span className="qualification__line"></span> 
            </div>
            <div className="qualification_textbox">
            <h3 className="qualification__title"><Trans i18nKey="legalintern"></Trans></h3>
              <span className="qualification__subtitle"><Trans i18nKey="tjpr"></Trans></span>
              <div className="qualification__calender">
                <i className="uil uil-calendar-alt"></i> 2018-2020
              </div>
            </div>
          </div>
        </div>

        <div className={`qualification__content ${activeTab === 'education' ? '' : 'qualification__hide'}`}>

          <div className="qualification__data">
            <div className="qualification_textbox margin-law-thing">
              <h3 className="qualification__title"><Trans i18nKey="law"></Trans></h3>
              <span className="qualification__subtitle"> 
                <Trans i18nKey="ufpr"></Trans>
              </span>
              <div className="qualification__calender">
                <i className="uil uil-calendar-alt"></i> 2018-2023
              </div>
            </div>

            <div className='line'>
              <span className="qualification__rounder"></span>
              <span className="qualification__line"></span>
            </div>
          </div>
      
          <div className="qualification__data">
            <div></div>
            <div>
              <span className="qualification__rounder"></span>
              <span className="qualification__line"></span>
            </div>
            <div className="qualification_textbox">
              <h3 className="qualification__title"><Trans i18nKey="ads"></Trans></h3>
              <span className="qualification__subtitle"><Trans i18nKey="up"></Trans></span>
              <div className="qualification__calender">
                <i className="uil uil-calendar-alt"></i> 2020-2023
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
