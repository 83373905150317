import 'react-multi-carousel/lib/styles.css';
import { FaUniversity } from 'react-icons/fa';
import { MdComputer } from 'react-icons/md';
import { IoMdGlobe } from 'react-icons/io';
import { GiMagnifyingGlass } from 'react-icons/gi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Trans } from 'react-i18next';
import React, { useState } from 'react';

export const Skills = () => {

  const [activeCard, setActiveCard] = useState(null);

  const handleCardClick = (cardId) => {
    if (activeCard === cardId) return setActiveCard(null
      );
    setActiveCard(cardId);
  }

  return (
  <section className='skills section' id='skills'>
    <h2 className="section__title"><Trans i18nKey="skills"></Trans></h2>
    {/* <span className="section__subtitle">Minhas habilidades</span> */}
    <div className="page-wrapper">  
    <div 
        className={`card-wrapper ${activeCard === 'lawyer' ? 'active' : ''}`} 
        onClick={() => handleCardClick('lawyer')}
      > 
      <div className="card-details">
          <h3 className="card-title"><Trans i18nKey="lawyer"></Trans><div className="margin"><FaUniversity/></div><div className="arrow-icon"><ExpandMoreIcon/></div></h3>
        <p></p>
      </div>
      
      <div className="reveal-details">
        <p><Trans i18nKey="details_laywer"></Trans></p>
      </div>
    </div>


    <div 
        className={`card-wrapper ${activeCard === 'developer' ? 'active' : ''}`} 
        onClick={() => handleCardClick('developer')}
      >
      <div className="card-details">
        <h3 className="card-title"><Trans i18nKey="developer"></Trans><div className="margin"><MdComputer/></div><div className="arrow-icon"><ExpandMoreIcon/></div></h3>
        <p></p>
      </div>
      
      <div className="reveal-details">
        <p><Trans i18nKey="details_developer"></Trans></p>
      </div>
    </div>
    </div>
    <div className="page-wrapper">
    <div 
        className={`card-wrapper ${activeCard === 'international' ? 'active' : ''}`} 
        onClick={() => handleCardClick('international')}
      >
      <div className="card-details">
        <h3 className="card-title"><Trans i18nKey="international"></Trans><div className="margin"><IoMdGlobe/></div><div className="arrow-icon"><ExpandMoreIcon/></div></h3>
        <p></p>
      </div>
      
      <div className="reveal-details">
        <p><Trans i18nKey="details_international"></Trans> <a href="https://www.itu.int/en/ITU-D/Regional-Presence/Americas/Documents/GC/2021/Annoucement-GC-AMS.pdf">Generation Connect</a> <Trans i18nKey="and"></Trans> <a href="https://www.internetsociety.org/fellowships/early-career/fellows/2021/">Early Carrer Fellowship</a>. 
        </p>
      </div>
    </div>

    <div 
        className={`card-wrapper ${activeCard === 'researcher' ? 'active' : ''}`} 
        onClick={() => handleCardClick('researcher')}
      >
      <div className="card-details">
        <h3 className="card-title"><Trans i18nKey="researcher"></Trans><div className="margin"><GiMagnifyingGlass/></div><div className="arrow-icon"><ExpandMoreIcon/></div></h3>
        <p></p>
      </div>
      
      <div className="reveal-details">
        <p><Trans i18nKey="details_researcher"></Trans><a href="https://educapes.capes.gov.br/bitstream/capes/704781/2/Juscibern%C3%A9tica%20-%20a%20liberdade%20e%20o%20controle%20algor%C3%ADtmico%20na%20sociedade%20da%20informa%C3%A7%C3%A3o.pdf"><Trans i18nKey="book_chapters"></Trans></a>,<a href="https://drive.google.com/file/d/1U8CrjLxxLOnhvKJvG1QWDAKg2_UM_h9l/view"> <Trans i18nKey="events"></Trans></a><Trans i18nKey="details_researcher_end"></Trans></p>
      </div>
    </div>
    
    </div>
  </section>
  )
}
